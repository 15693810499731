<template>
  <div>
    <div class="vx-row">
        <div class="vx-col w-full mt-8">
            <vx-card>
                <div class="card-title">
                    <h2>Email Message Templates </h2>
                </div>
                <div class="add-new-number justify-end">
                    <vs-button title="Add New Message Template" @click="addpnewtemplateActive=true, addOrEditEmailTemplateTitle = 'Create New Email Template', getEmailTrackClientTemplateGroupPrivateList()" color="primary" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn mb-2 w-full sm:w-auto" > Add New Message Template</vs-button>
                    <vs-button title="Add New Folder" @click="addpnewfolderActive=true, addOrEditFolderTitle = 'Create New Folder'" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn mb-2 ml-0 sm:ml-4 w-full sm:w-auto bg-actionbutton" > Add New Folder</vs-button>
                </div>
                <!-- All Templates -->
                <div class="mt-8" v-for="(folders, index) in templateList" :key="index">
                    <div class="flex justify-between items-center mb-4">
                        <h4>{{folders.Group_Name}}</h4>
                        <div>
                            <vs-button
                                v-if="folders.Id != '111111111111111111111111'"
                                @click="addpnewfolderActive=true, addOrEditFolderTitle = 'Edit Folder', getEmailTrackClientTemplateGroupForEdit(folders.Id)"                                
                                title="Edit Folder"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-edit"
                                class="mr-2 float-left bg-actionbutton"
                            ></vs-button>
                            <vs-button
                                v-if="folders.Id != '111111111111111111111111'"
                                @click="deleteFolderPopupActive=true, getEmailTrackClientTemplateGroupForDelete(folders.Id)"
                                title="Delete Folder"
                                color="danger"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-trash-2"
                                class="mr-0"
                            ></vs-button>
                        </div>
                    </div>
                    <!-- <div class="table-row-select">
                        <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                        <vs-select-item
                            :key="index"
                            :value="item.value"
                            :text="item.text"
                            v-for="(item,index) in recordList"
                        />
                        </vs-select>
                    </div> -->
                    <vs-table :data="folders.Templates" :max-items="perPage" pagination class="mb-8">
                        <template slot="thead">
                            <vs-th></vs-th>            
                            <vs-th>Template Name</vs-th>
                            <vs-th>Subject</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td width="90">
                                    <div class="table-action">
                                    <vs-button title="Edit" @click="addpnewtemplateActive=true, addOrEditEmailTemplateTitle = 'Edit Email Template', getEmailTrackClientTemplateForEdit(tr.Id)" color="primary" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left"></vs-button>
                                    <vs-button title="Delete" @click="deletepopupActive=true, getEmailTrackClientTemplateForDelete(tr.Id)" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                                    </div>
                                </vs-td>
                                <vs-td>
                                    {{tr.Template_Name}}
                                </vs-td>
                                <vs-td>
                                    {{tr.Subject}}
                                </vs-td>                 
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </vx-card>
        </div>
    </div>   
    <!-- Delete email template Popup -->      
    <vs-popup class="delete-popup" :active.sync="deletepopupActive"> 
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon"><feather-icon icon="Trash2Icon"></feather-icon></div>                    
            <h4>Delete Email Template?</h4>                    
            <p>{{deletableEmailTemplateName}}</p>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" @click="deleteEmailTrackClientTemplate()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button @click="cancelEmailDeletion()" color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>            
    </vs-popup>
    <!-- Delete folder Popup -->      
    <vs-popup class="delete-popup" :active.sync="deleteFolderPopupActive"> 
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon"><feather-icon icon="Trash2Icon"></feather-icon></div>                    
            <h4>Delete Email Folder?</h4>                    
            <p>{{deletableFolderName}}</p>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" @click="deleteEmailTrackClientTemplateGroup()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button @click="cancelFolderDeletion()" color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>            
    </vs-popup>
    <!-- Add/Edit New Folder -->
    <vs-popup class="tag-info" :title="addOrEditFolderTitle" :active.sync="addpnewfolderActive">
        <template>
            <div class="vx-row mb-3">                 
                <div class="vx-col input-text w-full">
                    <div class="popup-icon mb-10"><img src="../../../src/assets/images/folder.svg"></div> 
                    <vs-input type="type" class="w-full" label-placeholder="Folder Name" v-model="createFolder.Group_Name" />
                    <span class="text-danger text-sm error-msg w-full float-left">{{ createFolderErrorMessage }}</span>
                    <p>This must be a unique name for tracking your folder.</p>
                </div>
                <div class="vx-col input-text w-full mt-6">
                    <vs-checkbox v-model="createFolder.IsShared" :disabled="createFolder.Id">Shared</vs-checkbox>
                </div>
            </div>
        </template>
        <div class="flex justify-end modal-action-buttons w-full float-left mt-6 mb-8">
            <!-- for create -->            
            <vs-button color="primary" type="filled" v-if="createFolder.Id == '000000000000000000000000'" @click="createEmailTrackClientTemplateGroup()">Create Folder</vs-button>
            <!-- for update -->
            <vs-button  type="filled" v-if="createFolder.Id != '000000000000000000000000'" @click="updateEmailTrackClientTemplateGroup()" color="primary">Update Folder</vs-button>
            <vs-button @click="cancelFolderCreation()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>
    <!-- Add/Edit New Template Number -->
    <vs-popup class="add-new" :title="addOrEditEmailTemplateTitle" :active.sync="addpnewtemplateActive">
        <template>
            <form data-vv-scope="createEmailTemplateForm">
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Template Name" v-model="createEmailTemplate.Template_Name" v-validate="'required'" name="templateName"/>
                        <span class="text-danger text-sm error-msg">{{ errors.first('createEmailTemplateForm.templateName') }}</span>
                        <span class="text-danger text-sm error-msg">{{ createEmailTemplateErrorMessage }}</span>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-checkbox class="inline-flex" v-model="createEmailTemplate.IsShared" @change="getEmailFolder($event)">Shared</vs-checkbox>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <span class="vs-input--label">Folder</span>                    
                        <v-select label="Group_Name" placeholder="Folder" :options="folderOptions" v-model="createEmailTemplate.Group_Name_Id" :reduce="option => option.Id" :clearable="false"/>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Subject" v-model="createEmailTemplate.Subject"/>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <ejs-richtexteditor ref="rteObj" v-model="createEmailTemplate.Email_Body" :toolbarSettings="toolbarSettings"  v-validate="'required'" name="messageBody" :height='300'></ejs-richtexteditor>
                        <span class="text-danger text-sm error-msg">{{ errors.first('createEmailTemplateForm.messageBody') }}</span>
                    </div>
                </div> 
            </form>           
        </template>            
        <div class="flex justify-end flex-wrap modal-action-buttons w-full float-left mt-6 mb-8">
            <!-- for create -->
            <vs-button class="w-full sm:w-auto mb-2 sm:mb-0" color="primary" type="filled" @click="createEmailTrackClientTemplate()" v-if="!createEmailTemplate.Id">Create Email Template</vs-button>
            <!-- for update -->
            <vs-button class="w-full sm:w-auto mb-2 sm:mb-0" color="primary" type="filled" @click="updateEmailTrackClientTemplate()" v-if="createEmailTemplate.Id">Update Email Template</vs-button>
            <vs-button @click="cancelEmailTemplateCreation()" color="grey" type="border" class="ml-0 sm:ml-4 w-full sm:w-auto grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import VxCard from "@/components/vx-card/VxCard";
import vSelect from 'vue-select';
import { Validator } from "vee-validate";

import { RichTextEditorPlugin, Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar } from "@syncfusion/ej2-vue-richtexteditor";

Vue.use(RichTextEditorPlugin);

const dict = {
    custom: {
        templateName: {
            required: "A name is required."
        },
        messageBody: {
            required: "An email body is required."
        }
    }
};

Validator.localize("en", dict);

export default {
    provide: {
            richtexteditor:[Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar]
        },
    components: {
        VxCard,
        vSelect,
    },
    data() {
        return {
            toolbarSettings: {
                items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                'LowerCase', 'UpperCase', 'SuperScript', 'SubScript' , '|',
                'Formats', 'Alignments', '|', 'CreateLink', 'Image', '|', 'SourceCode']
            },

            templateList: [],
            perPage: 5,
            // recordList: [
            //     {text: 5, value: 5},
            //     {text: 10, value: 10},
            //     {text: 25, value: 25},
            //     {text: 50, value: 50},
            //     {text: 100, value: 100}
            // ],

            //Delete
            deletepopupActive:false,
            deletableEmailTemplateName: null,
            deletableEmailTemplateId: null,

            //Folder
            addpnewfolderActive:false,
            createFolder: {
                Date_Created: "0001-01-01T00:00:00",
                Group_Name: null,
                Id: '000000000000000000000000',
                IsActive: true,
                IsGroup: true,
                IsShared: false,
                Template_Name: ""
            },
            addOrEditFolderTitle: null,
            createFolderErrorMessage: null,
            deleteFolderPopupActive: false,
            deletableFolderName: null,
            deletableFolderId: null,

            //Create email template
            addpnewtemplateActive:false,
            createEmailTemplate: {
                Id: "",
                Template_Name: null,
                Subject: null,
                Email_Body: null,
                IsShared: false,
                Group_Name_Id: null
            },
            addOrEditEmailTemplateTitle: null,
            createEmailTemplateErrorMessage: null,
            folderOptions:[],
        }
    },
    created(){
        this.getEmailTrackClientTemplateList()
    },
    methods: {
        //Cancel email deletion
        cancelEmailDeletion(){
            this.deletepopupActive = false
            this.deletableEmailTemplateName = null
            this.deletableEmailTemplateId = null
        },
        //Cancel folder creation
        cancelFolderCreation(){
            this.addpnewfolderActive = false
            this.createFolderErrorMessage = null
            Object.assign(this.$data.createFolder, this.$options.data().createFolder)
        },
        //Cancel folder deletion
        cancelFolderDeletion(){
            this.deleteFolderPopupActive = false
            this.deletableFolderName = null
            this.deletableFolderId = null    
        },
        //Get email folder
        getEmailFolder(e){
            if(e.type == 'change'){
                if(this.createEmailTemplate.IsShared){
                    this.getEmailTrackClientTemplateGroupSharedList()
                }else{
                    this.getEmailTrackClientTemplateGroupPrivateList()        
                }
            }
        },
        //Cancel email template creation
        cancelEmailTemplateCreation(){
            this.addpnewtemplateActive = false
            this.folderOptions = []
            this.createEmailTemplateErrorMessage = null
            setTimeout(() => { 
                this.errors.clear('createEmailTemplateForm')
            }, 20);
            Object.assign(this.$data.createEmailTemplate, this.$options.data().createEmailTemplate)
        },
        
        //Get list of email template folder wise
        getEmailTrackClientTemplateList(){
            this.$vs.loading()
            this.axios.get("/ws/EmailTrackMessageTemplate/GetEmailTrackClientTemplateList").then(response => {
                let data = response.data;
                this.templateList = data
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });
        },
        //Get email template by id for deletion
        getEmailTrackClientTemplateForDelete(id){
            this.$vs.loading()
            this.axios.get("/ws/EmailTrackMessageTemplate/GetEmailTrackClientTemplate?id="+id).then(response => {
                let data = response.data;
                this.deletableEmailTemplateName = data.Template_Name
                this.deletableEmailTemplateId = data.Id
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });
        },
        //Delete email template
        deleteEmailTrackClientTemplate(){
            this.$vs.loading()
            this.axios.get("/ws/EmailTrackMessageTemplate/DeleteEmailTrackClientTemplate?id="+this.deletableEmailTemplateId).then(() => {
                this.getEmailTrackClientTemplateList()
                this.cancelEmailDeletion()
                this.$vs.notify({
                    title:'Success',
                    text: 'Your email template has been deleted successfully.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });    
        },
        //Create email template
        createEmailTrackClientTemplate(){
            this.$validator.validateAll('createEmailTemplateForm').then(result => {
                if (result) {
                    this.$vs.loading()
                    this.axios.post("/ws/EmailTrackMessageTemplate/CreateEmailTrackClientTemplate", this.createEmailTemplate).then(() => {
                        this.getEmailTrackClientTemplateList()
                        this.cancelEmailTemplateCreation()
                        this.$vs.notify({
                            title:'Success',
                            text: 'Your email template has been added successfully.',
                            color: "success",
                            iconPack: 'feather',
                            icon:'icon-check',
                            position: "top-right",
                            time:4000
                        });
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.$vs.loading.close()
                        this.showError(e);
                        this.createEmailTemplateErrorMessage = e.response.data.Errors[0].Message
                    });
                }
            })   
        },
        //Get private folders list
        getEmailTrackClientTemplateGroupPrivateList(){
            this.folderOptions = []
            this.$vs.loading()
            this.axios.get("/ws/EmailTrackMessageTemplateGroup/GetEmailTrackClientTemplateGroupPrivateList").then(response => {
                let data = response.data;
                if(!this.createEmailTemplate.Id){
                    this.createEmailTemplate.Group_Name_Id = data[0].Id
                }
                this.$refs.rteObj.refreshUI();
                this.folderOptions = data
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });    
        },
        //Get shared folders list
        getEmailTrackClientTemplateGroupSharedList(){
            this.folderOptions = []
            this.$vs.loading()
            this.axios.get("/ws/EmailTrackMessageTemplateGroup/GetEmailTrackClientTemplateGroupSharedList").then(response => {
                let data = response.data;
                if(!this.createEmailTemplate.Id){
                    this.createEmailTemplate.Group_Name_Id = data[0].Id
                }
                this.folderOptions = data
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });
        },
        //Get email template by id for edit
        getEmailTrackClientTemplateForEdit(id){
            this.$vs.loading()
            this.axios.get("/ws/EmailTrackMessageTemplate/GetEmailTrackClientTemplate?id="+id).then(response => {
                let data = response.data;
                this.createEmailTemplate = data
                if(this.createEmailTemplate.IsShared){
                    this.getEmailTrackClientTemplateGroupSharedList()
                }else{
                    this.getEmailTrackClientTemplateGroupPrivateList()
                }
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });
        },
        //Update email template
        updateEmailTrackClientTemplate(){
            this.$validator.validateAll('createEmailTemplateForm').then(result => {
                if (result) {
                    this.$vs.loading()
                    this.axios.post("/ws/EmailTrackMessageTemplate/UpdateEmailTrackClientTemplate?id="+this.createEmailTemplate.Id, this.createEmailTemplate).then(() => {
                        this.getEmailTrackClientTemplateList()
                        this.cancelEmailTemplateCreation()
                        this.$vs.notify({
                            title:'Success',
                            text: 'Your email template has been updated successfully.',
                            color: "success",
                            iconPack: 'feather',
                            icon:'icon-check',
                            position: "top-right",
                            time:4000
                        });
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.$vs.loading.close()
                        this.showError(e);
                        this.createEmailTemplateErrorMessage = e.response.data.Errors[0].Message
                    });
                }
            })    
        },
        //Create Folder
        createEmailTrackClientTemplateGroup(){
            this.$vs.loading()
            this.axios.post("/ws/EmailTrackMessageTemplateGroup/CreateEmailTrackClientTemplateGroup", this.createFolder).then(() => {
                this.getEmailTrackClientTemplateList()
                this.cancelFolderCreation()
                this.$vs.notify({
                    title:'Success',
                    text: 'Your folder has been added successfully.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.createFolderErrorMessage = e.response.data.Errors[0].Message
            });    
        },
        //Get folder by id for deletion
        getEmailTrackClientTemplateGroupForDelete(id){
            this.$vs.loading()
            this.axios.get("/ws/EmailTrackMessageTemplateGroup/GetEmailTrackClientTemplateGroup?id="+id).then(response => {
                let data = response.data;
                this.deletableFolderName = data.Group_Name
                this.deletableFolderId = data.Id
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });    
        },
        //Delete folder
        deleteEmailTrackClientTemplateGroup(){
            this.$vs.loading()
            this.axios.get("/ws/EmailTrackMessageTemplateGroup/DeleteEmailTrackClientTemplateGroup?id="+this.deletableFolderId).then(() => {
                this.getEmailTrackClientTemplateList()
                this.cancelFolderDeletion()
                this.$vs.notify({
                    title:'Success',
                    text: 'Your email folder has been deleted successfully.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });    
        },
        //Get folder by id for edit
        getEmailTrackClientTemplateGroupForEdit(id){
            this.$vs.loading()
            this.axios.get("/ws/EmailTrackMessageTemplateGroup/GetEmailTrackClientTemplateGroup?id="+id).then(response => {
                let data = response.data;
                this.createFolder = data
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });    
        },
        //Update Folder
        updateEmailTrackClientTemplateGroup(){
            this.$vs.loading()
            this.axios.post("/ws/EmailTrackMessageTemplateGroup/UpdateEmailTrackClientTemplateGroup?id="+this.createFolder.Id, this.createFolder).then(() => {
                this.getEmailTrackClientTemplateList()
                this.cancelFolderCreation()
                this.$vs.notify({
                    title:'Success',
                    text: 'Your email template has been updated successfully.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.createFolderErrorMessage = e.response.data.Errors[0].Message
            });    
        },
    }
};
</script>
<style>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
</style>
